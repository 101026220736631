body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all ease-in-out 0.5s;
}

.heading{
  font-family: 'Albert Sans', sans-serif;
  font-weight: 600;
}

code {
  font-family:  'Courier New';
}

body::-webkit-scrollbar{
  width: 0;
}

.table thead th{
  vertical-align: top;
}




