:root {
	--bs-success: #04325e;
	--bs-green: #04325e;
	--bs-blue: #04325e;
	--blue: #04325e;
	--primary: #04325e;
	--bs-btn-bg: #04325e;
	--bs-primary: #04325e;
	--bs-btn-border-color: #04325e;
	--bs-btn-hover-border-color: #04325e;
	--bs-pagination-color: var(--blue) !important;
}

*{
	font-size:14px !important
}

.btn-primary {
	--bs-btn-bg: rgb(30, 86, 160) !important;
	--bs-primary: rgb(30, 86, 160) !important;
	--bs-btn-border-color: rgb(30, 86, 160) !important;
	--bs-btn-hover-border-color: rgb(30, 86, 160) !important;
}

.bg-success {
	background-color: var(--bs-success) !important;
	border: 1px solid var(--bs-green) !important;
}

.bg-success:hover {
	background-color: var(--bs-success) !important;
	border: 1px solid var(--bs-green) !important;
}

a {
	color: #163172;
}

.text-system-primary {
	color: #163172;
}

.cursor-pointer {
	cursor: pointer !important;
}

.disableSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.navlink:active {
	background-color: #163172;
}

.exportIcon {
	color: #163172 !important;
	margin-right: 10px;
}

input[type="checkbox"] {
	-webkit-appearance: checkbox !important;
	-moz-appearance: checkbox !important;
	-ms-appearance: checkbox !important;
	-o-appearance: checkbox !important;
	appearance: checkbox !important;
	background-color: transparent;
}

.pagination {
	--bs-pagination-color: var(--bs-blue) !important;
	--bs-pagination-hover-color: var(--bs-blue) !important;
	--bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
	--bs-pagination-active-bg: var(--bs-blue);
	--bs-pagination-active-border-color: var(--bs-blue);
}

.pagination-selector {
	margin: 0 5px;
	height: 30px;
	border: 1px solid #04325e;
	border-radius: 5px;
}

.page-item.active .page-link {
	background-color: var(--blue) !important;
	border-color: var(--blue) !important;
}

.hidedropdown::after {
	content: none;
	display: hidden !important;
}

.builder {
	flex-direction: row-reverse !important;
}

#save-buttons {
	display: flex;
	align-items: center;
	margin-left: auto;
}

#save-buttons div {
	width: 100%;
}

#save-buttons div > span {
	width: 100% !important;
}

.nav-link:hover {
	color: var(--bs-teal);
}

.breadcrumb-item > a {
	text-decoration: none;
}

.cursor-pointer {
	cursor: pointer;
}

.arrow-hide::after {
	display: none !important;
}

.arrow-hide::before {
	display: none !important;
}

.arrow-hide:focus {
	outline: none !important;
	outline-color: transparent !important;
	outline-width: 0px !important;
}

.hover-primary:hover {
	background-color: var(--bs-blue);
}

.hover-warning:hover {
	background-color: #1e56a0;
	color: #fff;
}

.hover-warning:hover a {
	color: #fff !important;
}

.bg-primary {
	background-color: #163172 !important;
	color: #fff !important;
}

.hover-primary:hover {
	background-color: #163172 !important;
	color: #fff !important;
}

.nav-pills .nav-link.active {
	background-color: #04325e !important;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 10px;
	cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
	cursor: pointer;
}
.methodsBuilder {
	background-color: rgb(248, 249, 250) !important;
}

.exportIcon {
	color: #163172 !important;
	margin-right: 10px;
}

/* #heading-layout{
    display: none!important;
}
#heading-data {
    display: none !important;
}
#heading-premium {
    display: none !important;

} */

.sidebar-inner::-webkit-scrollbar {
	width: 0px !important;
	display: none;
}

aside {
	padding: 0 10px;
	background-color: #d6e4f0 !important;
}

aside > div {
	position: static !important;
}

.sidebar-inner {
	background-color: transparent !important;
}

.menu-item {
	margin: 1px 0px;
}

.navactive {
	background-color: #163172 !important;
	color: #fff !important;
	border-radius: 10px;
	overflow: hidden;
}

/* .cxRqFw{
    background-color: #163172 !important;
    color: #fff !important;
} */
.menu-anchor {
	background-color: inherit !important;
}

.sub-menu-content {
	padding: 0 5px;
	border-radius: 5px;
}

.popup {
	margin-left: -10.5%;
}

th {
	white-space: nowrap;
	/* width: 200px !important; */
}
tr {
	vertical-align: center;
}

td {
	min-height: 90px;
	height: 100%;

	/* background-color: #04325e; */
}

.queryBuilder-branches .ruleGroup .ruleGroup-header select {
	width: 10%;
}

@media (min-width: 1080px) and (max-width: 1400px) {
	.popup {
		margin-left: -14.5%;
	}

	/* .idmodule {
            right: 12%;
        }
         */
}

@media (min-width: 761px) and (max-width: 1080px) {
	.popup {
		margin-left: -20%;
	}

	/* .idmodule {
            right:10%;
         }
                 .versionModule {
                     position: absolute;
                     right: 5%;
                 } */
}

@media (min-width: 500px) and (max-width: 760px) {
	.popup {
		margin-left: -53%;
	}

	.queryBuilder-branches .ruleGroup .ruleGroup-header select {
		width: 50%;
	}

	/* .idmodule {
                       right: 10%;
                   } */
}

@media (max-width: 490px) {
	.popup {
		margin-left: -53%;
	}

	/* .iconAdder 
    {
        position: relative;
        left: 0px;
        padding: 0;
    } */

	/* .adminForm {
            position: relative;
            left:0px;
            padding:0
        } */

	.queryBuilder-branches .ruleGroup .ruleGroup-header select {
		width: 50%;
	}
}

/* dashboard icon */

.togglebutton-right {
	position: relative;
}

.togglebutton-right::after {
	position: absolute;
	right: 10px !important;
	top: 15px;
}

.signatureContainer {
	border: 1px solid gray;
	width: 100%;
	height: 100px;
}

.darkOverlay {
	background-color: rgba(0, 0, 0, 0.5);
}

.load-wrapper {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: rgb(211, 211, 211);
	z-index: 44;
	overflow: hidden;
	border-radius: 5px;
}

.activity {
	position: absolute;
	left: -45%;
	height: 100%;
	width: 45%;
	background-image: linear-gradient(
		to left,
		rgba(251, 251, 251, 0.05),
		rgba(251, 251, 251, 0.3),
		rgba(251, 251, 251, 0.6),
		rgba(251, 251, 251, 0.3),
		rgba(251, 251, 251, 0.05)
	);
	background-image: -moz-linear-gradient(
		to left,
		rgba(251, 251, 251, 0.05),
		rgba(251, 251, 251, 0.3),
		rgba(251, 251, 251, 0.6),
		rgba(251, 251, 251, 0.3),
		rgba(251, 251, 251, 0.05)
	);
	background-image: -webkit-linear-gradient(
		to left,
		rgba(251, 251, 251, 0.05),
		rgba(251, 251, 251, 0.3),
		rgba(251, 251, 251, 0.6),
		rgba(251, 251, 251, 0.3),
		rgba(251, 251, 251, 0.05)
	);
	animation: loading 1s infinite;
	z-index: 45;
}

@keyframes loading {
	0% {
		left: -45%;
	}

	100% {
		left: 100%;
	}
}

/* query builder */
.queryBuilder .ruleGroup {
	background-color: white !important;
	box-shadow: 1px 0px 5px var(--bs-gray-500);
	border: none;
}

.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid {
	background-color: rgba(102, 51, 153, 0.4);
}
.validateQuery
	.queryBuilder
	.ruleGroup.queryBuilder-invalid
	.ruleGroup-addRule {
	font-weight: bold !important;
}
.validateQuery
	.queryBuilder
	.ruleGroup.queryBuilder-invalid
	> .ruleGroup-header::after {
	content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
	color: rgb(5, 5, 5);
}
.validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value {
	background-color: rgba(207, 16, 16, 0.4) !important;
}
.validateQuery
	.queryBuilder
	.rule.queryBuilder-invalid
	.rule-value::placeholder {
	color: rgb(211, 25, 66) !important;
}

.activeTab {
	/* border:solid green !important; */
	border-bottom: 2px solid green !important;
}

.sticky-col {
	position: -webkit-sticky;
	position: sticky;
	background-color: rgba(255, 255, 255, 1) !important;
	z-index: 1;
}

.dropdown-menu {
	z-index: 999999999999 !important;
}

.pulse {
	animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 3, 187, 0.2);
	}

	100% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
}

.required {
	position: relative;
}

.required::after {
	position: absolute;
	content: "*";
	color: red;
	font-size: 12px;
	left: 100%;
	top: 0;
}
